@charset "UTF-8";

/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use '@angular/material' as mat;

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// Include the Angular Material theming mixins
@include mat.core(); // Include this once, ideally in your main file

// Include the base theme mixins
@include mat.all-component-themes($app-theme);

// Include legacy component typographies from Angular Material.
//@include mat.all-legacy-component-typographies();

// Include the legacy core styles from Angular Material.
//@include mat.legacy-core();

// Include the theme styles for all legacy components in your app using the base theme.
//@include mat.all-legacy-component-themes($app-theme);

.dark-theme {
  // Include the dark theme mixin where you apply the dark theme class
  @include mat.all-component-themes($custom-theme);
  // Apply the dark theme styles to all legacy components when the .dark-theme class is active.
  //@include mat.all-legacy-component-themes($custom-theme);
}

// Theme customization
@import "theme/theme";

// Rich text editor styles
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
