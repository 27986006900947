* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--primary-background-color);

  @media screen and (min-width: 769px) {
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    overflow-y: auto;
    height: auto;
    min-height: 100%;
  }
}

.main-title {
  font-size: 22px;
  transition: all 0.5s ease;

  @media screen and (max-width: 550px) {
    font-size: 17px !important;
  }
}

.loader {
  top: 50%;
  margin: auto;
}