/*
 * Application global variables.
 */
@use '@angular/material' as mat;

// Main app theme
$app-primary: mat.define-palette(mat.$deep-purple-palette);
$app-accent: mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);
$app-theme: mat.define-light-theme((
        color: (
                primary: $app-primary,
                accent: $app-accent,
                warn: $app-warn,
        )
));

// Dark theme
$custom-theme-primary: mat.define-palette(mat.$indigo-palette);
$custom-theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$custom-theme-warn: mat.define-palette(mat.$pink-palette);
$custom-theme: mat.define-dark-theme((
        color: (
                primary: $custom-theme-primary,
                accent: $custom-theme-accent,
                warn: $custom-theme-warn,
        )
));
