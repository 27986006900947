.small-icon {
    mask-repeat: no-repeat;
    mask-size: contain;
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-color: var(--main-nav-menu-item-icon-color);
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.title-icon {
    visibility: collapse;
}

.home-icon {
    mask-image: url(/app/assets/app/home-icon.svg);
}

.settings-icon {
    mask-image: url(/app/assets/app/settings-icon.svg);
}

.gauge-icon {
    mask-image: url(/app/assets/app/gauge-icon.svg);
}

.users-manage-icon {
    mask-image: url(/app/assets/app/users-manage-icon.svg);
}

.bell-icon {
    mask-image: url(/app/assets/app/bell-icon.svg);
}

.bolt-icon {
    mask-image: url(/app/assets/app/bolt-icon.svg);
}

.analytics-icon {
    mask-image: url(/app/assets/app/analytics-icon.svg);
}

.dashboards-icon {
    mask-image: url(/app/assets/app/dashboards-icon.svg);
}

.admin-icon {
    mask-image: url(/app/assets/app/admin-icon.svg);
}

.snackbar-success {
    background-color: #26c74e !important;
    color: whitesmoke !important;
    box-shadow: none !important;
}

.snackbar-error {
    background-color: #e20909 !important;
    color: whitesmoke !important;
    box-shadow: none !important;
}

.snackbar-success .mat-button,
.snackbar-error .mat-button {
    color: #505050 !important;
}

.mat-tooltip.large-font {
    font-size: 13px;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

::ng-deep .mat-mdc-menu-panel {
    max-width: none !important;
}
